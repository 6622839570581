<template>
  <div>
    <div class="pageHeader" style="overflow: hidden">
      <div style="float:left">
        <el-button type="success" icon="el-icon-refresh" @click="initPageData()">刷新</el-button>
      </div>
      <div style="float:right;">
        <el-input placeholder="名称/编号" v-model="keyword" class="input-with-select" style="width:350px;"></el-input>&nbsp;
        <el-button type="primary" @click="handle_search()" icon="el-icon-search">搜索</el-button>
      </div>
    </div>

    <el-table :data="page.list" v-loading="showLoading" element-loading-text="拼命加载中" border width="width:100%; height:50%">
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="reportno" label="报告编号" width="150"></el-table-column>
      <el-table-column prop="name" label="报告名称" width="150"></el-table-column>
      <el-table-column prop="custid" label="客户" width="150"></el-table-column>
      <el-table-column prop="orderid" label="订单" width="100"></el-table-column>
      <el-table-column prop="schemeid" label="方案" width="100"></el-table-column>
      <el-table-column prop="examopinion" label="审核意见" width="100"></el-table-column>
      <el-table-column prop="downloadcnt" label="下载次数" width="100"></el-table-column>
      <el-table-column prop="reviewcnt" label="预览次数" width="100"></el-table-column>
      <el-table-column prop="status" label="状态" width="50"></el-table-column>
      <el-table-column prop="scope" label="操作" min-width="100"></el-table-column>
    </el-table>
    <el-pagination @current-change="devlogChange"
                   :current-page="page.pageNum"
                   :page-size="page.pageSize" layout="total, prev, pager, next, jumper"
                   :total="page.total">
    </el-pagination>
  </div>
</template>

<script>
import REPORT from '../../api/biz/api_report';
import syscomm from '../../utils/syscomm';
import COMM from "../../api/base/api_comm";
import ORDER from "@/api/biz/api_order";

export default {
  name: "reportfinalizelist", // 定稿报告列表
  props: {
    keyword: {
      type: String
    }
  },
  data() {
    return {
      showLoading: false,
      page: {list: [], pageNum: 1, pageSize: 10, total: 0}
    }
  },
  // 初始化加载
  mounted() {
    this.init();
  },
  // 方法定义
  methods: {
    init() {
      this.initPageData();
    },

    // 加载页面数据
    initPageData() {
      this.showLoading = true;

      // 执行查询
      REPORT.list({
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        keyword: this.keyword
      }).then((res) => {
        this.showLoading = false;
        if (200 == res.code) {
          var data = res.pageData;
          this.page.total = data.total;
          this.page.list = data.list;
        } else {
          this.$message.error(res.msg);
          this.page.list = [];
        }
      });
    },
    devlogChange(pageNum) {
      this.page.pageNum = pageNum;
      this.initPageData();
    },

    // 查询
    handle_search() {
      this.initPageData();
    }
  }
}
</script>

<style scoped>
.pageHeader {
  background-color: white;
  border-bottom: 1px solid #CCCCCC;
  font-size: 18px;
  padding: 5px 10px;
}

.red {
  color: #ff0000;
}
</style>